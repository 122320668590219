var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "home" },
    [
      _c(
        "b-container",
        { staticClass: "home-container" },
        [
          _c("b-row", [_c("b-col", [_c("div", { staticClass: "logo" })])], 1),
          _c(
            "b-row",
            [
              _vm.busy
                ? _c(
                    "b-col",
                    { staticClass: "text-center pt-5", attrs: { sm: "12" } },
                    [
                      _c("div", {
                        staticClass: "loader spinner-border spinner-border-sm",
                        attrs: { role: "status", "aria-hidden": "true" }
                      })
                    ]
                  )
                : _c(
                    "b-col",
                    { attrs: { sm: "12" } },
                    [
                      _c("h1", [_vm._v("Rejoignez la communauté Evenir")]),
                      _c("p", [
                        _vm._v(
                          " Chargez votre véhicule via un réseau de hôtes particuliers, gagnez de l’argent en devenant founisseur. Commencez à utiliser notre service : "
                        )
                      ]),
                      _c(
                        "b-button",
                        {
                          attrs: {
                            variant: "dark",
                            id: "signup",
                            "router-tag": "button"
                          },
                          on: { click: _vm.signup }
                        },
                        [_vm._v("S'INSCRIRE")]
                      ),
                      _c("hr", { staticClass: "my-4" }),
                      _c(
                        "b-button",
                        {
                          attrs: {
                            variant: "light",
                            id: "login",
                            "router-tag": "button"
                          },
                          on: { click: _vm.login }
                        },
                        [_vm._v("SE CONNECTER")]
                      )
                    ],
                    1
                  )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }