<template>
  <div class="home">
    <b-container class="home-container">
      <b-row>
        <b-col>
          <div class="logo"></div>
        </b-col>
      </b-row>
      <b-row>
        <b-col v-if="busy" class="text-center pt-5" sm="12">
          <div
            class="loader spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></div>
        </b-col>
        <b-col v-else sm="12">
          <h1>Rejoignez la communauté Evenir</h1>
          <p>
            Chargez votre véhicule via un réseau de hôtes particuliers, gagnez
            de l’argent en devenant founisseur. Commencez à utiliser notre
            service :
          </p>
          <b-button
            variant="dark"
            id="signup"
            @click="signup"
            router-tag="button"
            >S'INSCRIRE</b-button
          >
          <hr class="my-4" />
          <b-button
            variant="light"
            id="login"
            @click="login"
            router-tag="button"
            >SE CONNECTER</b-button
          >
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { isLoggedIn, isNew } from '../utils/auth-utils'
import { Routes } from '@/router.js'

export default {
  name: 'home',
  data() {
    return {
      busy: false
    }
  },
  async beforeRouteEnter(to, from, next) {
    if (await isLoggedIn()) {
      if (isNew()) {
        next(Routes.DEFINE_USER_TYPE)
      } else {
        next(vm => {
          vm.busy = true
          vm.$store
            .dispatch('loadVehicles', { api: vm.$apiService })
            .then(() => {
              // TODO: Check for invalid parameters ?
              if (vm.$store.getters.hasVehicles) {
                vm.$router.push(Routes.SEARCH)
              } else {
                vm.$router.push(Routes.MENU)
              }
            })
            .catch(err => {
              console.error(
                '[Home] Uncaught error while loading vehicles: ',
                err
              )
              vm.$router.push(Routes.MENU)
            })
        })
      }
    } else {
      next(vm => {
        if (vm.$authService.needPasswordResetRedirect() == true) {
          vm.$authService.passwordResetRedirect()
        }
      })
    }
  },
  methods: {
    login() {
      this.$authService.loginRedirect()
    },
    signup() {
      this.$authService.signupRedirect()
    },
    passowrdReset() {
      this.$authService.passwordResetRedirect()
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/styles.scss';

.home {
  height: 667px;
  color: #fff;
  background-image: url('../assets/img/evenir-small.png');
  background-size: cover;
  height: 100vh;
  background-repeat: no-repeat;
  background-position: center;

  .r {
    height: 50%;
    overflow: hidden;
  }

  .logo {
    width: 120px;
    height: 110px;
    margin: 180px auto 0;
    background-image: url('../assets/img/logo.png');
  }

  hr {
    border-color: #fff;
    width: 15%;
  }

  h1 {
    font-size: 20px;
    font-weight: $font-weight-bold;
    margin-top: 24px;
  }

  .btn {
    width: 100%;
  }

  .loader {
    width: 50px;
    height: 50px;
  }
}

#login.btn {
  color: #2998d8;
}

.home-container {
  max-width: $inner-content-max-width-s;
}

@include media-breakpoint-up(sm) {
  .home {
    background-image: url('../assets/img/evenir-small.png');
  }
}

@include media-breakpoint-up(md) {
  .home {
    background-image: url('../assets/img/evenir-small@2x.png');
  }
}

@include media-breakpoint-up(lg) {
  .home {
    background-image: url('../assets/img/evenir-large.png');
  }
}

@include media-breakpoint-up(xl) {
  .home {
    background-image: url('../assets/img/evenir-large@2x.png');
  }
}
</style>
